import Report from "../components/Report";

const Resources = () => {
  return (
    <main className="wrapper">
      <Report />
    </main>
  )
}

export default Resources;