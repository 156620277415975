import MediaList from "../components/MediaList";

const NewsRoom = () => {
  return (
    <main className="wrapper">
      <MediaList />
    </main>
  )
}

export default NewsRoom;